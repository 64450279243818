<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div style="display: flex; flex-wrap: nowrap;">
          <div>
            <div class="document-header-row px-0 pt-2 pb-2">
              <div style="flex: 0 0 170px; margin-right: 16px">
                <date_input v-model="date" label="Дата: " :time="true" required :disabled="false"
                            @userChange="onDateChange" id="date"/>
              </div>
              <div style="flex: 0 0 110px; margin-right: 16px">
                <string_input v-model="number" label="Номер: " :disabled="true"/>
              </div>
              <div style="flex: 0 0 110px; margin-right: 16px">
                <select_input
                    v-model="currency_id"
                    :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                    :computed-getter="'get_currency_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    label="Валюта: "
                    :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                    @selectChange="onHeaderChange"
                />
              </div>
              <div style="flex: 0 0 310px; margin-right: 16px;">
                <select_input
                    v-model="operation"
                    :import-getter="{ name: 'tax_bill_document_income_operation' }"
                    :input-value-as-value="false"
                    :disabled="without_accounting"
                    clearable
                    label="Операція: "
                    @selectChange="onOperationChange"
                />
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-2">
              <div style="flex: 0 0 296px; margin-right: 16px">
                <select_input
                    v-model="contractor_id"
                    :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :action-props="{currency_id: currency_id, date: date}"
                    :show-additional-field="true"
                    label="Контрагент"
                    :show-select-on-focus="false"
                    :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {currency_id: currency_id, date: date},
                                showAdditionalField: true
                              }"
                    @selectChange="onHeaderChange"
                />
              </div>
              <div style="flex: 0 0 436px; margin-right: 16px">
                <select_input
                    v-model="contract_id"
                    :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_contract_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :owner_id="contractor_id"
                    label="Договір"
                    :show-select-on-focus="false"
                    :sync-action-props="true"
                    :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
                    @selectChange="onHeaderChange"
                />
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-2">
              <div style="flex: 0 0 296px; margin-right: 16px">
                <select_input
                    v-model="document_reason_type"
                    :import-getter="{ name: 'tax_bill_income_document_type' }"
                    :input-value-as-value="false"
                    label="Вид документа підстави"
                    clearable
                    @selectChange="documentTypeChange"
                />
              </div>
              <div style="flex: 0 0 436px; margin-right: 16px">
                <select_input
                    v-if="document_reason_type"
                    label="Документ підстава"
                    v-model="document_reason_id"
                    :computed-action="documents_select[document_reason_type].action_select"
                    :computed-getter="documents_select[document_reason_type].getter_select"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="true"
                    :action-props="{date_to: date, contractor_id: contractor_id}"
                    :select-button-object="{
                                name: document_reason_type,
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: documents_select[document_reason_type].list_title,
                                element_title: documents_select[document_reason_type].element_title,
                                show: true,
                                document: true,
                                full_screen: true,
                                action_props: {date_to: date, contractor_id: contractor_id}
                              }"
                    @selectChange="documentChange"
                />
                <string_input label="Документ підстава" v-else disabled/>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="grey lighten-4 py-2 px-3 document-info-box">
            <div class="document-info-box-element">
              <div class="info-box-element-title">Підсумки</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">без ПДВ:</div>
                  <div class="element-item-value">{{ footer_total.table.sum_without_tax }} <span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title">ПДВ:</div>
                  <div class="element-item-value">{{ footer_total.table.tax_sum }} <span>грн.</span></div>
                </div>
              </div>
            </div>
            <div class="document-info-box-element">
              <div class="info-box-element-title">Стан розрахунків</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">з ПДВ:</div>
                  <div class="element-item-value">{{ footer_total.table.sum_with_tax }} <span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title"></div>
                  <div class="element-item-value"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Реквізити документа
                </v-tab>
                <v-tab>
                  Таблична частина
                </v-tab>
                <v-tab>
                  Скраги та ін.
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%">
                  <div style="width: 100%; height: 97%; background-color: white" class="py-1 px-3 mt-2">
                    <div class="block-divider ml-1">
                      Бухгалтерський облік
                    </div>
                    <div class="d-flex flex-nowrap pl-3">
                      <div style="flex: 0 0 254px; margin-right: 16px">
                        <select_input
                            v-model="tax_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            label="Рахунок ПДВ"
                            :show-select-on-focus="false"
                            :action-filter="'@field=type@;@start-with=S@64'"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@64',
                              show: true
                            }"
                            clearable
                            @selectChange="onHeaderChange"
                        />
                      </div>
                      <div style="flex: 0 0 254px; margin-right: 16px">
                        <select_input
                            v-model="tax_correction_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            label="Рахунок корегування ПДВ"
                            :show-select-on-focus="false"
                            :action-filter="'@field=type@;@start-with=S@64'"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@64',
                              show: true
                            }"
                            clearable
                            @selectChange="onHeaderChange"
                        />
                      </div>
                      <div style="flex: 0 0 238px; margin-right: 16px">
                        <select_input
                            v-model="tax_conditional_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            label="Рахунок умовного продажу"
                            :show-select-on-focus="false"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true
                            }"
                            clearable
                            @selectChange="onHeaderChange"
                        />
                      </div>
                    </div>
                    <div class="block-divider ml-1">
                      Податкова накладна
                    </div>
                    <div class="d-flex flex-nowrap pb-3 pl-3">
                      <div style="flex: 0 0 190px; margin-right: 16px">
                        <string_input v-model="number_income" label="Вхідний номер"/>
                      </div>
                      <div style="flex: 0 0 170px; margin-right: 16px">
                        <date_input v-model="date_income" label="Дата виписки"/>
                      </div>
                      <div style="flex: 0 0 170px; margin-right: 16px">
                        <date_input v-model="date_erpn" label="Дата реєстр. в ЄРПН" :required="false"/>
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px">
                        <v-checkbox v-model="check_in_erpn"
                                    hide-details
                                    color="success"
                                    label="Перевірити в ЄРПН" class="document-check-box"/>
                      </div>
                    </div>
                    <div class="d-flex flex-nowrap pb-3 pl-3">
                      <div style="flex: 0 0 376px; margin-right: 16px">
                        <select_input
                            v-model="special_mode"
                            :import-getter="{ name: 'tax_bill_spec_mode' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Спец.режим оподаткування: "
                        />
                      </div>
                      <div style="flex: 0 0 170px; margin-right: 16px;">
                        <date_input v-model="date_get" label="Дата отримання" :required="false"/>
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px">
                        <v-checkbox v-model="number_income_get_from_erpn"
                                    hide-details
                                    color="success"
                                    label="Отримано в елек. вигляді" class="document-check-box"/>
                      </div>
                    </div>
                    <div class="d-flex flex-nowrap pl-3">
                      <div style="flex: 0 0 190px; margin-right: 16px">
                        <v-checkbox v-model="included_in_the_clarifying"
                                    hide-details
                                    color="success"
                                    label="Включ. в уточ. розрах." class="document-check-box"/>
                      </div>
                      <div style="flex: 0 0 170px; margin-right: 16px;">
                        <date_input v-model="clarifying_date" label="Період уточ./корег." :required="false"/>
                      </div>
                      <div style="flex: 0 0 386px; margin-right: 16px"

                      >
                        <select_input
                            v-model="document_type"
                            :import-getter="{ name: 'tax_bill_document_type' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Вид документу: "
                            @selectChange="onHeaderChange"
                        />
                      </div>
                    </div>
                    <template v-if="operation === 'adjustment_calculation_return'
                                              || operation === 'adjustment_calculation_change_sum'">
                      <div class="block-divider ml-1">
                        Додатково
                      </div>
                      <div class="d-flex flex-nowrap pl-3">
                        <div style="flex: 0 0 190px; margin-right: 16px">
                          <string_input v-model="number_correction" label="Номер (корег.)" :required="false"/>
                        </div>
                        <div style="flex: 0 0 170px; margin-right: 16px">
                          <date_input v-model="date_correction" label="Дата (корег.)" :required="false"/>
                        </div>
                        <div style="flex: 0 0 386px; margin-right: 16px">
                          <string_input v-model="correction_reason" label="Підстава (корег.)" :required="false"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearTable"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            ПДВ %
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума без ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума з ПДВ
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in table"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@pdv'"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                              transparent
                              @selectChange="tableTaxChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_without_tax"
                                        v-decimal transparent
                                        small field="tax"
                                        :required="false"
                                        @userChange="tableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.tax_sum"
                                        v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_with_tax"
                                        v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 230px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.table.sum_without_tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.table.tax_sum | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.table.sum_with_tax | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%">
                  <div style="width: 100%; height: 97%; background-color: white" class="py-1 px-3 mt-2">
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <document_print v-model="show_print"
                        :doc_type="'DOCUMENT_ADVANCE_REPORT'"
                        :doc_id="itemId"
                        :doc_form="'v1'"
                        v-if="!isNew"
        ></document_print>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
          <v-icon color="teal">mdi-printer</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
  UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
  REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import taxBillIncomeAPI from "@/utils/axios/accounting/documents/tax_bill_income"
import {maxBy} from "lodash";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {round_number} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import {subconto_type_select_setting, getDocumentsObject} from '@/utils/accounting'
import bankAccountOutcomeAPI from "@/utils/axios/accounting/documents/bank_outcome";
import actWorkPerformedIncome from "@/utils/axios/accounting/documents/act_work_performed_income";
import invoiceWarehouseIncome from "@/utils/axios/accounting/documents/invoice_warehouse_income";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'tax_bill_income_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_TaxBillIncome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      subconto_type_select_setting,
      documents_select: {},
      footer_total: {
        table: {count: 0, sum_without_tax: 0, tax_sum: 0, sum_with_tax: 0},
      },
      without_accounting: this.item.without_accounting || false,
      operation: this.item.operation || null,
      currency_id: this.item.currency_id || null,
      contractor_id: this.item.contractor_id || null,
      contract_id: this.item.contract_id || null,
      document_reason_type: this.item.document_reason_type || null,
      document_reason_id: this.item.document_reason_id || null,
      number_income: this.item.number_income || null,
      date_income: this.item.date_income || null,
      check_in_erpn: this.item.check_in_erpn || false,
      date_erpn: this.item.date_erpn || null,
      number_income_get_from_erpn: this.item.number_income_get_from_erpn || false,
      date_get: this.item.date_get || null,
      included_in_the_clarifying: this.item.included_in_the_clarifying || false,
      clarifying_date: this.item.clarifying_date || null,
      special_mode: this.item.special_mode || null,
      number_correction: this.item.number_correction || null,
      date_correction: this.item.date_correction || null,
      correction_reason: this.item.correction_reason || null,
      document_type: this.item.document_type || null,
      tax_account_id: this.item.tax_account_id || null,
      tax_correction_account_id: this.item.tax_correction_account_id || null,
      tax_conditional_account_id: this.item.tax_conditional_account_id || null,
      complaint: this.item.complaint || null,
      table: []
    }
  },
  methods: {
    getDocumentsObject,

    onHeaderChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data

            if (this.isNew) {
              this.onOperationChange({value: this.operation})
              if (this.operation === 'tax_bill') {
                const tax_account_id = this.charts.find(c => c.code === '6412')
                const tax_correction_account_id = this.charts.find(c => c.code === '6442')
                const tax_conditional_account_id = this.charts.find(c => c.code === '6435')
                this.tax_account_id = (tax_account_id || {}).id
                this.tax_correction_account_id = (tax_correction_account_id || {}).id
                this.tax_conditional_account_id = (tax_conditional_account_id || {}).id
              }

              if (this.item.basic_create_id) {
                if (this.item.basic_create_type === 'DOCUMENT_BANK_OUTCOME') {
                  bankAccountOutcomeAPI.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        this.documentChange({id: this.item.basic_create_id})
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_CASH_ORDER_OUTCOME') {
                  bankAccountOutcomeAPI.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        this.documentChange({id: this.item.basic_create_id})
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_ACT_WORK_PERFORMED_INCOME') {
                  actWorkPerformedIncome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        this.documentChange({id: this.item.basic_create_id})
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_INVOICE_WAREHOUSE_INCOME') {
                  invoiceWarehouseIncome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        this.documentChange({id: this.item.basic_create_id})
                      })
                }

              }
            }
          })
    },
    onDateChange() {
      this.get_contractor_balance()
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    getTotals() {
      this.footer_total.table = this.table.reduce((acc, item) => {
        acc.count += 1
        acc.sum_without_tax += item.sum_without_tax || 0
        acc.tax_sum += item.tax_sum || 0
        acc.sum_with_tax += item.sum_with_tax || 0
        return acc
      }, {count: 0, sum_without_tax: 0, tax_sum: 0, sum_with_tax: 0})
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    getStartInfo() {
      this.getChartsOfAccounts()
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'operation': {id: 'operation', text: 'Не заповнена операція'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена одиниця виміру'},
        'contractor_id': {id: 'contractor_id', text: 'Не заповнений контрагент'},
        'contract_id': {id: 'contract_id', text: 'Не заповнений договір контрагент'},
        'document_reason_type': {id: 'document_reason_type', text: 'Не заповнений вид документа підстави'},
        'document_reason_id': {id: 'document_reason_id', text: 'Не заповнений документа підстави'},
        'number_income': {id: 'number_income', text: 'Не заповнений вхідний номер ПН'},
        'date_income': {id: 'date_income', text: 'Не заповнена вхідна дата ПН'},
        'special_mode': {id: 'special_mode', text: 'Не заповнений спец.режим оподаткування'},
        'document_type': {id: 'document_type', text: 'Не заповнений вид документа'},
        'tax_account_id': {id: 'tax_account_id', text: 'Не заповнений рахунок ПДВ'},
      }

      const document_table = {
        'tax_id': {id: 'tax_id', table: 'Таблиця', text: 'Не заповнена ставка ПДВ'},
        'sum_without_tax': {id: 'sum_without_tax', table: 'Таблиця', text: 'Не заповнена сума без ПДВ'},
        'tax_sum': {id: 'tax_sum', table: 'Таблиця', text: 'Не заповнена сума ПДВ'},
        'sum_with_tax': {id: 'sum_with_tax', table: 'Таблиця', text: 'Не заповнена сума з ПДВ'},
      }

      if (this.included_in_the_clarifying) {
        header['clarifying_date'] = {id: 'clarifying_date', text: 'Не заповнений період уточнення'}
      }

      if (this.operation === 'adjustment_calculation_return') {
        header['number_correction'] = {id: 'number_correction', text: 'Не заповнений номер корегування'}
        header['date_correction'] = {id: 'date_correction', text: 'Не заповнена дата корегування'}
        header['correction_reason'] = {id: 'correction_reason', text: 'Не заповнена причина корегування'}
      }

      if (this.operation === 'adjustment_calculation_change_sum') {
        header['number_correction'] = {id: 'number_correction', text: 'Не заповнений номер корегування'}
        header['date_correction'] = {id: 'date_correction', text: 'Не заповнена дата корегування'}
        header['correction_reason'] = {id: 'correction_reason', text: 'Не заповнена причина корегування'}
      }


      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(document_table, 'Таблиця', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }
          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.without_accounting = local_data.without_accounting || false
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.itemId = local_data.id || null
      this.operation = local_data.operation || null
      this.currency_id = local_data.currency_id || null
      this.contractor_id = local_data.contractor_id || null
      this.contract_id = local_data.contract_id || null
      this.document_reason_type = local_data.document_reason_type || null
      this.document_reason_id = local_data.document_reason_id || null
      this.number_income = local_data.number_income || null
      this.date_income = local_data.date_income || null
      this.check_in_erpn = local_data.check_in_erpn || false
      this.date_erpn = local_data.date_erpn || null
      this.number_income_get_from_erpn = local_data.number_income_get_from_erpn || false
      this.date_get = local_data.date_get || null
      this.included_in_the_clarifying = local_data.included_in_the_clarifying || false
      this.clarifying_date = local_data.clarifying_date || null
      this.special_mode = local_data.special_mode || null
      this.number_correction = local_data.number_correction || null
      this.date_correction = local_data.date_correction || null
      this.correction_reason = local_data.correction_reason || null
      this.document_type = local_data.document_type || null
      this.tax_account_id = local_data.tax_account_id || null
      this.tax_correction_account_id = local_data.tax_correction_account_id || null
      this.tax_conditional_account_id = local_data.tax_conditional_account_id || null
      this.complaint = local_data.complaint || null

      this.table = []
    },
    clearTable(table) {
      this[table] = []
      this.getTotals()
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    addTableRow() {
      const tax_id = this.settings?.pdv_20?.value || null

      this.table.push(
          {
            tax_id: tax_id,
            sum_without_tax: 0,
            tax_sum: 0,
            sum_with_tax: 0,
            economic_activity: true,
            taxable: true,
            proportional: false,
            fixed_assets: false,
            row_num: this.getNewRowNum('table'),
          }
      )
    },
    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      return tariffs[tax_id] || null
    },

    deleteTableRow(payload) {
      this.table.splice(this.table.indexOf(payload), 1)
      this.getTotals()
    },
    onOperationChange(payload) {
      const value = (payload || {}).value || null
      const tax_correction_account_6443 = this.charts.find(c => c.code === '6443')
      const tax_correction_account_6442 = this.charts.find(c => c.code === '6442')

      this.$nextTick(() => {
        if (value === 'tax_bill') {
          this.document_type = 'PN'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = false
          this.tax_correction_account_id = (tax_correction_account_6442 || {}).id
        }
        if (value === 'adjustment_calculation_return') {
          this.document_type = 'RK'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = true
          this.tax_correction_account_id = (tax_correction_account_6443 || {}).id
        }
        if (value === 'adjustment_calculation_change_sum') {
          this.document_type = 'RK'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = true
          this.tax_correction_account_id = (tax_correction_account_6443 || {}).id
        }
        if (value === 'tax_credit_recovery') {
          this.document_type = 'PN'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = false
          this.tax_correction_account_id = (tax_correction_account_6442 || {}).id
        }
        if (value === 'error_correction') {
          this.document_type = 'PN'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = false
          this.tax_correction_account_id = (tax_correction_account_6442 || {}).id
        }
        if (value === 'other') {
          this.document_type = 'BO'
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = false
          this.tax_correction_account_id = (tax_correction_account_6442 || {}).id
        }
        if (!value) {
          this.document_type = null
          this.special_mode = null
          this.included_in_the_clarifying = false
        }
      })
    },
    documentTypeChange() {
      this.document_reason_id = null
    },
    documentChange(payload) {
      const document_id = (payload || {}).id || (payload || {}).value

      if (document_id) {
        taxBillIncomeAPI.get_document_sum({
          document_type: this.document_reason_type,
          document_id: document_id
        })
            .then(response => response.data)
            .then(data => {
              data.forEach(item => {
                if (!this.table.length) {
                  this.table.push(
                      {
                        tax_id: item.tax_id,
                        sum_without_tax: item.sum_without_tax,
                        tax_sum: item.sum_tax,
                        sum_with_tax: item.sum_with_tax,
                        economic_activity: true,
                        taxable: true,
                        proportional: false,
                        fixed_assets: false,
                        row_num: this.getNewRowNum('table'),
                      }
                  )
                }
              })
            })
            .finally(() => {
              this.getTotals()
            })
      }
    },

    tableColChange(payload) {
      this.$nextTick(() => {
        const row = this.table.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
      })
    },
    calcRow(payload, col = '') {
      if (!payload) return
      const tax_tariff = this.getTaxTariff(payload.tax_id)

      if (col === 'sum_without_tax') {
        payload.tax_sum = round_number(payload.sum_without_tax * (tax_tariff / 100), 2)
        payload.sum_with_tax = payload.sum_without_tax + payload.tax_sum
      }
      if (col === 'tax_sum') {
        payload.sum_without_tax = round_number(payload.tax_sum * 100 / tax_tariff, 2)
        payload.sum_with_tax =  payload.sum_without_tax + payload.tax_sum
      }
      if (col === 'sum_with_tax') {
        payload.sum_without_tax =
            payload.sum_with_tax - round_number(payload.sum_with_tax / (100 / tax_tariff + 1), 2)
      }

      if (!col) {
        payload.tax_sum = round_number(payload.sum_without_tax * (tax_tariff / 100), 2)
        payload.sum_with_tax = payload.sum_without_tax + payload.tax_sum
      }

      this.getTotals()
    },

    tableTaxChange(payload) {
      const row = this.table.find(i => i.row_num === payload.row_num)
      if (!row) return
      this.$nextTick(() => {
        this.calcRow(payload, 'tax_id')
      })
    },

    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        without_accounting: this.without_accounting,
        date: this.date,
        operation: this.operation,
        currency_id: this.currency_id,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        document_reason_type: this.document_reason_type,
        document_reason_id: this.document_reason_id,
        number_income: this.number_income,
        date_income: this.date_income,
        check_in_erpn: this.check_in_erpn || false,
        date_erpn: this.date_erpn,
        number_income_get_from_erpn: this.number_income_get_from_erpn || false,
        date_get: this.date_get,
        included_in_the_clarifying: this.included_in_the_clarifying || false,
        clarifying_date: this.clarifying_date,
        special_mode: this.special_mode,
        number_correction: this.number_correction,
        date_correction: this.date_correction,
        correction_reason: this.correction_reason,
        document_type: this.document_type,
        tax_account_id: this.tax_account_id,
        tax_correction_account_id: this.tax_correction_account_id,
        tax_conditional_account_id: this.tax_conditional_account_id,
        complaint: this.complaint,
        table: this.table,
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      // return Promise.all([
      //   this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      // ])
    },
    fetch_data_by_id() {
      if (this.isNew) {
        this.getChartsOfAccounts()
        return
      }

      taxBillIncomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.without_accounting = data.without_accounting
            this.date = data.date
            this.conducted = data.conducted
            this.number = data.number
            this.operation = data.operation
            this.currency_id = data.currency_id
            this.contractor_id = data.contractor_id
            this.contract_id = data.contract_id
            this.document_reason_type = data.document_reason_type
            this.document_reason_id = data.document_reason_id
            this.number_income = data.number_income
            this.date_income = data.date_income
            this.check_in_erpn = data.check_in_erpn
            this.date_erpn = data.date_erpn
            this.number_income_get_from_erpn = data.number_income_get_from_erpn
            this.date_get = data.date_get
            this.included_in_the_clarifying = data.included_in_the_clarifying
            this.clarifying_date = data.clarifying_date
            this.special_mode = data.special_mode
            this.number_correction = data.number_correction
            this.date_correction = data.date_correction
            this.correction_reason = data.correction_reason
            this.document_type = data.document_type
            this.tax_account_id = data.tax_account_id
            this.tax_correction_account_id = data.tax_correction_account_id
            this.tax_conditional_account_id = data.tax_conditional_account_id
            this.complaint = data.date

            this.table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getTotals()
            this.getChartsOfAccounts()
          })
          .finally(() => {
            this.$nextTick(() => {
              this.checkDocument(true, true)
            })
          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення договору по ЦПХ № ${this.number} (${this.person_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearTableDialog() {
      const payload = {
        text: `Очистити таблицю?`,
        accept_button: true,
        id: 'clear_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument(false, false)
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_table') {
                if (payload.answer) {
                  this.clearTable('table')
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),

    footer_total_width() {
      if (this.show_detail_1 && this.show_detail_2 && this.show_detail_3) {
        return 1230
      }
      if (this.show_detail_1 && this.show_detail_2 && !this.show_detail_3) {
        return 1080
      }
      if (this.show_detail_1 && !this.show_detail_2 && !this.show_detail_3) {
        return 930
      }
      return 780
    },
    document_header_ready() {
      return this.document_state.header_ready
    }
  },
  created() {
    this.documents_select = this.getDocumentsObject()
  }
}
</script>
